import Core from '@/core/module';
import Cookie from '@/helpers/cookie';

export default class Newsticker extends Core {
    init() {
        this.id = this.$el.attr('newstickerid');
        this.expirationDays = this.$el.attr('newsticker-expiration-days');

        if (!Cookie.get(`newsticker-${this.id}`)) {
            this.$el.show();
        }
        this.addEventListeners();
    }

    addEventListeners() {
        this.$el.on('click', '.newsticker__close', (e) => {
            e.preventDefault();

            Cookie.set(`newsticker-${this.id}`, {
                expiration: `${this.expirationDays}`,
                value: 'hide',
            });

            this.$el.hide();
        });
    }
}
