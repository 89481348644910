import _ from 'lodash';

export default new class Gmaps {
    constructor() {
        this.appended = false;
        this.loaded = false;
        this.queue = [];
    }

    load(callback) {
        this.queue.push(callback);

        if (this.loaded) {
            this.runQueue();
        }

        if (this.appended === false && this.loaded === false) {
            this.appendScript();
        } else if (this.appended === true && this.loaded === true) {
            this.runQueue();
        }
    }

    runQueue() {
        this.loaded = true;

        _.each(this.queue, (item) => {
            item.call(window, window.twttr);
        });

        this.queue = [];
    }

    appendScript() {
        this.appended = true;

        $('head').append(`
            <script>
                window.twttr = (function(d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0],
                    t = window.twttr || {};
                  if (d.getElementById(id)) return t;
                  js = d.createElement(s);
                  js.id = id;
                  js.src = "https://platform.twitter.com/widgets.js";
                  fjs.parentNode.insertBefore(js, fjs);

                  t._e = [];
                  t.ready = function(f) {
                    t._e.push(f);
                  };

                  return t;
                }(document, "script", "twitter-wjs"));
            </script>
        `);

        window.twttr.ready(() => {
            this.runQueue();
        });
    }
}();
