import _ from 'lodash';
import Core from '@/core/module';
import TwitterLoader from '@/helpers/twitterloader';

export default class Twitter extends Core {
    init() {
        this.cookieconsentType = 'marketing';

        window.addEventListener('cookieconsent_change', () => {
            if (window.Cookieconsent[this.cookieconsentType]) {
                this.makeWidgets();
            }
        });
    }

    makeWidgets() {
        TwitterLoader.load(() => {
            _.each(this.$el, (el) => {
                window.twttr.widgets.createTweet(el.getAttribute('tweet-id'), el);
            });
        });
    }
}
