import Vue from 'vue';
import Core from '@/core/module';
import GmapsLoader from '@/helpers/gmapsloader/';
import store from './store';

export default class Mapgroups extends Core {
    init() {
        this.options = this.$el.data('options');

        console.log('map options', this.options);

        GmapsLoader.load(() => {
            this.loadMap();
        });
    }

    loadMap() {
        import('./components/App.vue').then((app) => {
            const App = app.default;

            function highLightFilter(value, query) {
                if (!value) {
                    return '';
                }

                return value.replace(new RegExp(query, 'gi'), (match) => {
                    const html = `<span class="highlightText">${match}</span>`;
                    return html;
                });
            }

            Vue.filter('highlight', highLightFilter);

            Vue.prototype.$options = this.options;

            new Vue({
                el: this.$el[0],
                store,
                render: h => h(App),
            });
        });
    }
}
