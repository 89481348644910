import Core from '@/core/module';

export default class ToggleElement extends Core {
    init() {
        this.addEventListeners();
        this.checkActiveFields();
    }

    addEventListeners() {
        this.$el.find('input').on('change', () => {
            this.checkActiveFields();
        });
    }

    checkActiveFields() {
        this.$el.each((index, element) => {
            const $element = $(element);

            const fieldData = $element.data('toggle-fields');

            let found = false;

            fieldData.values.forEach((field) => {
                if ($element.find(`input[type="radio"][value="${field}"]:checked`).length > 0) {
                    found = true;
                }
            });

            $(`[toggle-id="${fieldData.groupId}"]`).toggle(found);
        });
    }
}
