import Vue from 'vue';
import _ from 'lodash';
import axios from 'axios';

// getters
const getters = {
    getLocation: state => id => _.find(state.locations, { id }),
};

// actions
const actions = {
    loadLocations(context, ids) {
        if (ids.length === 0) {
            return;
        }

        axios.get(Vue.prototype.$options.endpoints.location_items, {
            params: {
                ids,
            },
        }).then((response) => {
            _.each(response.data, (location) => {
                context.commit('addLocation', {
                    ...location,
                    loading: false,
                });
            });
        });
    },
};

// mutations
const mutations = {
    addLocation(state, location) {
        Vue.set(state.locations, location.id, location);
    },
};

// initial state
const state = {
    locations: [],
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
