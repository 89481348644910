export default new class GA {
    static check() {
        if (typeof dataLayer !== 'object') {
            console.error('Tagmanager not implemented!');
            return false;
        }

        return true;
    }

    event(data, callback) {
        if (this.check) {
            console.log('send GA event: ', data);

            window.dataLayer.push({
                event: 'event',
                eventAction: data.action,
                eventCategory: data.category,
                eventLabel: data.label,
                eventValue: data.value,
                eventCallback: callback || function cb() {},
            });
        }
    }

    pageview(data, callback) {
        if (this.check) {
            console.log('send GA pageview: ', data);

            window.dataLayer.push({
                event: 'pageview',
                pageviewPath: data.path,
                eventCallback: callback || function cb() {},
            });
        }
    }
}();
