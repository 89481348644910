import { TweenMax } from 'gsap';
import Core from '@/core/module';

export default class ScrollTo extends Core {
    init() {
        window.scrollToId = function (id) {
            TweenMax.to('body, html', 0.75, {
                scrollTop: $(`#${id}`).offset().top - 150,
            });
        };
    }
}
