module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<li data-inputname="' +
((__t = ( inputName )) == null ? '' : __t) +
'">' +
((__t = ( message )) == null ? '' : __t) +
'</li>';

}
return __p
};
