import { TweenMax, TimelineMax } from 'gsap';
import Core from '@/core/module';

export default class Mainnav extends Core {
    init() {
        this.addEventListeners();

        this.currentTimeline = null;

        this.text = this.$el.find('.mainnav__menu-toggle__text').text();
    }

    addEventListeners() {
        this.$el.on('click', '.mainnav__toggle-search', (e) => {
            e.preventDefault();

            this.openSearch();
        });

        this.$el.on('click', '.search-field__button--close', (e) => {
            e.preventDefault();

            this.closeSearch();
        });

        this.$el.on('blur', '.mainnav__search-bar input', () => {
            this.closeSearch();
        });

        this.$el.on('keydown', '.mainnav__search-bar input', (e) => {
            if (e.keyCode === 13) {
                $(e.currentTarget).closest('form').submit();
            }
        });

        this.$el.on('click', '.mainnav__menu-toggle button', () => {
            if (this.$el.hasClass('mainnav--open')) {
                this.closeMainnav();
            } else {
                this.openMainnav();
            }
        });

        this.$el.on('click', '.mainnav__bg, .mainnav__close', () => {
            this.closeMainnav();
        });

        $(document).on('keyup', (e) => {
            if (e.keyCode === 27) {
                this.closeMainnav();
            }
        });
    }

    openSearch() {
        $('.mainnav__search-bar').show();

        TweenMax.fromTo('.mainnav__search-bar', 0.2, { scale: 0.9, autoAlpha: 0 }, { scale: 1, autoAlpha: 1 });

        setTimeout(() => {
            $('.mainnav__search-bar input')[0].focus();
        }, 100);

        return this;
    }

    closeSearch() {
        TweenMax.to('.mainnav__search-bar', 0.2, {
            scale: 0.9,
            autoAlpha: 0,
            onComplete: () => {
                $('.mainnav__search-bar').hide();
            },
        });

        return this;
    }

    openMainnav() {
        this.$el.addClass('mainnav--open');

        this.$el.find('.mainnav__menu-toggle__text').text('sluiten');

        this.$el.find('.hamburger').addClass('hamburger--close');

        this.$el.find('.mainnav__nav').show();
        this.$el.find('.mainnav__bg').show();

        const tl = new TimelineMax();
        TweenMax.killTweensOf(this.$el.find('.mainnav__nav'));
        tl.fromTo(this.$el.find('.mainnav__nav'), 0.2, { top: -20, autoAlpha: 0 }, { top: 0, autoAlpha: 1 }, 0);
        tl.to(this.$el.find('.mainnav__bg'), 0.2, { autoAlpha: 1 }, 0);
    }

    closeMainnav() {
        this.$el.removeClass('mainnav--open');
        this.$el.find('.mainnav__menu-toggle__text').text(this.text);

        this.$el.find('.hamburger').removeClass('hamburger--close');

        const tl = new TimelineMax();
        TweenMax.killTweensOf(this.$el.find('.mainnav__nav'));
        tl.to(this.$el.find('.mainnav__nav'), 0.2, { top: -20,
            autoAlpha: 0,
            onComplete: () => {
                this.$el.find('.mainnav__nav').hide();
                this.$el.find('.mainnav__bg').hide();
            } }, 0);
        tl.to(this.$el.find('.mainnav__bg'), 0.2, { autoAlpha: 0 }, 0);
    }
}
