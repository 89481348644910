import _ from 'lodash';

export default new class Gmaps {
    constructor() {
        this.appended = false;
        this.loaded = false;
        this.queue = [];
    }

    load(callback) {
        if (!window.siteOptions.mapsApiKey) {
            console.error('window.siteOptions.mapsApiKey not defined!');
            return;
        }

        this.queue.push(callback);

        window.mapLoaded = () => {
            this.loaded = true;
            this.runQueue();
        };

        if (this.appended === false && this.loaded === false) {
            this.appendScript();
        } else if (this.appended === true && this.loaded === true) {
            this.runQueue();
        }
    }

    runQueue() {
        _.each(this.queue, (item) => {
            item.call(window, window.google.maps);
        });

        this.queue = [];
    }

    appendScript() {
        this.appended = true;
        $('head').append(`
            <script src="
                https://maps.googleapis.com/maps/api/js?key=${window.siteOptions.mapsApiKey}&callback=window.mapLoaded&libraries=places
            ">
            </script>
        `);
    }
}();
