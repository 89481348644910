import Core from '@/core/module';
import Cookie from '@/helpers/cookie';

export default class Newsletter extends Core {
    init() {
        this.setDefaults({
            close: '.newsletter-popup .close',
            submit: '.newsletter-popup .btn',
            defaultTimeout: 15,
        });

        this.runTimer();
        this.addEventListeners();
    }

    addEventListeners() {
        $(this.options.close).on('click', (e) => {
            e.preventDefault();
            this.close();
        });

        $(this.options.submit).on('click', (e) => {
            e.preventDefault();
            this.close();
            window.location.href = e.target;
        });
    }

    removeEventListeners() {
        this.$el.off('click');
    }

    runTimer() {
        setTimeout(() => {
            this.removeEventListeners();
        }, 1000);

        if (!Cookie.get('newsletter_hide', Boolean)) {
            if (Cookie.get('newsletter_timeout', Number) === 0) {
                this.open();
            } else {
                Cookie.set('newsletter_animate', {
                    expiration: 1,
                    value: 1,
                });

                let currentTimeout = Cookie.get('newsletter_timeout', Number);

                if (!currentTimeout || currentTimeout < 0) {
                    currentTimeout = this.options.defaultTimeout;
                }

                const newsletterTimer = setInterval(() => {
                    if (currentTimeout === 0) {
                        clearInterval(newsletterTimer);

                        this.open();
                    }

                    Cookie.set('newsletter_timeout', {
                        expiration: 1,
                        value: currentTimeout,
                    });

                    currentTimeout -= 1;
                }, 1000);
            }
        } else {
            $(this).remove();
        }
    }

    open(noanimate) {
        if (noanimate) {
            this.$el.css('bottom', 10).show();
            return false;
        }

        this.$el.css('bottom', -this.$el.outerHeight()).show();
        this.$el.animate({ bottom: 10 }, 350);

        return this;
    }

    close() {
        Cookie.set('newsletter_hide', {
            expiration: 1,
            value: 1,
        });
        this.$el.animate({ bottom: -this.$el.outerHeight() }, 350, () => {
            this.$el.remove();
        });
    }
}
