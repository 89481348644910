import '../../sass/index.scss';
import 'custom-event-polyfill';
import _ from 'lodash';
import Router from '@/core/router';
import ModuleMobilenav from '@/modules/mobilenav';
// import ClippingImage from '@/modules/clippingimage';
import CsrfToken from '@/modules/csrftoken';
import ModuleMainnav from '@/modules/mainnav';
import ModuleTwitter from '@/modules/twitter';
import ModuleFavorites from '@/modules/favorites';
import ModuleToggleElement from '@/modules/toggleElement';
import ModuleMap from '@/modules/map';
import ModuleNewsletter from '@/modules/newsletter';
import ModuleNewsticker from '@/modules/newsticker';
import ModuleDoublecheck from '@/modules/doublecheck';
import ModuleScrollTo from '@/modules/scrollTo';
import ModuleAjaxForm from '@/modules/ajaxform';

window._ = {
    escape: _.escape,
};

Router.setup({
    options: {
        pagecontainer: '.page-container',
        defaultTransition: 'fade',
        ajaxloading: false,
    },
    modules: [
        {
            class: ModuleNewsletter,
            element: '.newsletter-popup',
            single: true,
        },
        {
            load: () => import('@/modules/logoslider'),
            element: '.logoslider',
        },
        {
            class: ModuleMobilenav,
            element: '.mobile-nav',
        },
        {
            class: ModuleMainnav,
            element: '.mainnav',
        },
        {
            class: ModuleAjaxForm,
            element: '[ajaxform]',
        },
        {
            class: ModuleTwitter,
            element: '.tweet',
        },
        {
            load: () => import('@/modules/faq'),
            element: '.faq',
        },
        // {
        //     class: ClippingImage,
        //     element: '[clip]',
        // },
        {
            load: () => import('@/modules/locationmap'),
            element: '.location-map',
            single: true,
        },
        {
            load: () => import('@/modules/partners'),
            element: '.partners',
        },
        {
            load: () => import('@/modules/cookie'),
            element: '#cookieconsent',
        },
        {
            class: ModuleFavorites,
            element: document,
        },
        {
            // map
            class: ModuleMap,
            element: '#map',
        },
        {
            load: () => import('@/modules/pageoverview'),
            element: '.vue-page-overview',
            single: true,
        },
        {
            load: () => import('@/modules/highlight-slider'),
            element: '.highlight-slider',
        },
        {
            load: () => import('@/modules/figures'),
            element: '.figures',
        },
        {
            load: () => import('@/modules/dates'),
            element: '.dates',
        },
        {
            load: () => import('@/modules/multiselect'),
            element: 'select[multiple], select[advanced]',
            single: true,
        },
        {
            load: () => import('@/modules/filterbar'),
            element: '.filterbar',
            single: true,
        },
        {
            class: ModuleToggleElement,
            element: '[data-toggle-fields]',
        },
        {
            element: '.newsticker',
            class: ModuleNewsticker,
        },
        {
            class: ModuleDoublecheck,
            element: 'body',
        },
        {
            class: ModuleScrollTo,
            element: 'body',
        },
        {
            class: CsrfToken,
            element: '.csrf-token',
        },
    ],
});
