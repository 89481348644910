import _ from 'lodash';
import { TimelineMax } from 'gsap';
import Core from '@/core/module';
import Cookie from '@/helpers/cookie';

export default class Favorites extends Core {
    init() {
        this.nrOfFavorites = this.storage.length;

        this.addEventListeners();
        this.checkFavorites();
    }

    addEventListeners() {
        this.$el.on('click', '[data-favorite]', (e) => {
            const $el = $(e.currentTarget);
            const data = $el.data('favorite');

            this.toggleItem(data);
        });
    }

    toggleItem(item) {
        const cookieData = this.storage;
        const alreadyExists = !!(_.find(this.storage, { id: item.id }));

        if (alreadyExists) {
            this.storage = _.filter(cookieData, (favoriteItem) => {
                if (favoriteItem.id === item.id) {
                    return false;
                }

                return true;
            });
        } else {
            cookieData.push(item);

            this.storage = cookieData;
        }

        this.checkFavorites();

        return item;
    }

    checkFavorites() {
        _.each(this.$el.find('[data-favorite]'), (favoriteItem) => {
            const $favoriteItem = $(favoriteItem);
            const data = $favoriteItem.data('favorite');
            const alreadyExists = !!(_.find(this.storage, { id: data.id }));

            $favoriteItem.toggleClass('favorite--active', alreadyExists);
        });

        $('[favorite-count]').text(this.storage.length);

        if (this.nrOfFavorites !== this.storage.length) {
            const tl = new TimelineMax();
            tl.to('.favorite-cta', 0.2, { scale: 1.3 });
            tl.to('.favorite-cta', 0.2, { scale: 1 });

            this.nrOfFavorites = this.storage.length;
        }
    }

    get storage() {
        let cookieData = Cookie.get('favorites', JSON);

        if (!cookieData) {
            cookieData = [];
        }

        return cookieData;
    }

    set storage(value) {
        Cookie.set('favorites', {
            value,
            expiration: 365,
        });
    }
}
