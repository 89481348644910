import { TweenMax } from 'gsap';
import Core from '@/core/module';
import GA from '@/helpers/ga';

export default class Mobilemenu extends Core {
    init() {
        this.setDefaults({
            container: '.mobile-nav-aside',
            toggleElement: '.mobile-nav__toggle',
            bg: '.mobile-nav-aside-bg',
            overlay: '.mobile-nav-aside-overlay',
            isOpen: false,
        });

        this.addEventListeners();
    }

    addEventListeners() {
        $(document).on('click.mobileMenu', this.options.toggleElement, (e) => {
            e.preventDefault();

            this.toggle();
        });

        $(this.options.overlay).on('click.mobileMenu', (e) => {
            e.preventDefault();

            this.close();
        });

        this.$el.on('click', '.mobile-nav__search-toggle', (e) => {
            e.preventDefault();

            this.openSearch();
        });

        this.$el.on('blur', '.mobile-nav__search-bar input', () => {
            this.closeSearch();
        });

        this.$el.on('click', '.mobile-nav__search-bar .search-field__button--close', (e) => {
            e.preventDefault();
        });
    }

    removeEventListeners() {
        $(this.options.toggleElement).off('click.mobileMenu');
        $(this.options.overlay).off('click.mobileMenu');
    }

    toggle() {
        if (this.options.isOpen) {
            this.close();

            this.options.isOpen = false;
        } else {
            this.open();

            this.options.isOpen = true;
        }
    }

    open() {
        if (this.options.isOpen) {
            return;
        }

        this.options.isOpen = true;
        $(this.options.overlay).stop().show().animate({
            opacity: 0.5,
        }, 600);

        $(this.options.container).addClass('show');
        $(this.options.bg).addClass('show');

        GA.event({
            category: 'mobile menu',
            action: 'click',
            label: 'open',
        });
    }

    close() {
        if (!this.options.isOpen) {
            return;
        }

        this.options.isOpen = false;
        $(this.options.overlay).stop().animate({
            opacity: 0,
        }, 600, () => {
            $(this.options.overlay).hide();
        });

        $(this.options.container).removeClass('show');
        $(this.options.bg).removeClass('show');

        GA.event({
            category: 'mobile menu',
            action: 'click',
            label: 'close',
        });
    }

    openSearch() {
        $('.mobile-nav__search-bar').show();

        TweenMax.fromTo('.mobile-nav__search-bar', 0.2, { scale: 0.9, autoAlpha: 0 }, { scale: 1, autoAlpha: 1 });

        setTimeout(() => {
            $('.mobile-nav__search-bar input')[0].focus();
        }, 100);

        return this;
    }

    closeSearch() {
        TweenMax.to('.mobile-nav__search-bar', 0.2, {
            scale: 0.9,
            autoAlpha: 0,
            onComplete: () => {
                $('.mobile-nav__search-bar').hide();
            },
        });

        return this;
    }
}
