import Events from '@/helpers/events';

export default class Core {
    constructor(el, options = {}, allowMultiple) {
        const events = new Events();
        events.bind(this);

        if (el) {
            this.$el = $(el);

            if (this.$el.length === 0) {
                return;
            }

            if (this.$el.length > 1 && allowMultiple === false) {
                console.error('$el cant be multiple');
                return;
            }
        }

        this.options = options;

        this.init();
    }

    static init() {

    }

    setDefaults(options) {
        this.options = Object.assign(options, this.options);
    }
}
