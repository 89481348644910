import _ from 'lodash';
import Core from '@/core/module';

export default class Doublecheck extends Core {
    init() {
        _.each(this.$el.find('form.form'), (el) => {
            const $el = $(el);
            const method = ($el.attr('method') && $el.attr('method') === 'post') ? 'post' : 'get';

            if (method === 'post') {
                $el.find('.doubleCheck').val(window.siteOptions.websiteID);
            }
        });
    }
}
